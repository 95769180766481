<template>
  <div class="container py-4">
    <TypeBarClinicManage :type="'ws-mgmt'" />
    <div class="row pt-3">
      <p class="fs-20 fw-700 txt-grey-900 mb-4">{{$t('workspace_setting.lbl_staff')}}</p>
      <div class="d-flex mb-0">
        <div
          class="d-flex justify-content-center align-items-center p-2 cursor-pointer w-10"
          :class="
            type == 'personnel' ? 'link' : ''
          "
          @click="changeType('personnel')"
        >
          <span
            class="robo-14-500"
            :class="type == 'personnel' ? 'txt-pri' : ''"
          >{{$t('workspace_setting.lbl_joined')}}</span>
        </div>
        <div
          class="d-flex justify-content-center align-items-center p-2 cursor-pointer w-10"
          :class="
            type == 'sent' ? 'link' : ''
          "
          @click="changeType('sent')"
          v-if="isOwnerWorkspace"
        >
          <span
            class="robo-14-500"
            :class="type == 'sent' ? 'txt-pri' : ''"
          >{{$t('workspace_setting.lbl_invited_list')}}</span>
        </div>
        <div
          class="d-flex justify-content-center align-items-center p-2 cursor-pointer w-10"
          :class="
            type == 'approval' ? 'link' : ''
          "
          @click="changeType('approval')"
          v-if="isOwnerWorkspace"
        >
          <span
            class="robo-14-500"
            :class="type == 'approval' ? 'txt-pri' : ''"
          >{{$t('workspace_setting.lbl_request_list')}} {{waiting_count ? '(' + waiting_count + ')' : ''}}</span>
        </div>
      </div>
    </div>
    <div v-if="type === 'approval' && isOwnerWorkspace">
      <DoctorWorkspaceClinic @waitingCount="waitingCount"></DoctorWorkspaceClinic>
    </div>
    <div v-if="type === 'sent' && isOwnerWorkspace">
      <InvitedDoctors />
    </div>
    <div v-if="type === 'personnel'">
      <ListStaff></ListStaff>
    </div>
  </div>
</template>

<script>
import TypeBarClinicManage from '../../components/Doctor/TypeBarClinicManage.vue'
import DoctorWorkspaceClinic from '../../components/Clinic/_DoctorWorkspaceClinic.vue'
import ListStaff from '../../components/Clinic/ListStaff'
import InvitedDoctors from '../../components/Clinic/InvitedDoctors.vue'

export default {
  name: 'ClinicPersonnelManage',
  components: {
    TypeBarClinicManage,
    DoctorWorkspaceClinic,
    ListStaff,
    InvitedDoctors
  },
  data () {
    return {
      type: 'personnel',
      waiting_count: 0
    }
  },
  computed: {
    isOwnerWorkspace () {
      let clinic = this.$globalClinic
      let user = this.$user
      if (!clinic || !clinic.id || !user || !user.doctor) return
      return clinic.doctor_id === user.doctor.id
    }
  },
  mounted () {
    this.type = this.$route.query.type
      ? this.$route.query.type.toLowerCase()
      : 'personnel'
  },
  methods: {
    changeType (str_type) {
      let self = this
      self.type = str_type
      self.$router
        .push({ path: '/ws-mgmt', query: { type: self.type } })
        .catch(() => {})
    },
    waitingCount (n) {
      this.waiting_count = n
    }
  }
}
</script>

<style scoped>
.link {
  border-bottom: 2px solid #20419b;
}
.w-10 {
  width: 150px;
}
</style>
