<template>
    <JoinClinic></JoinClinic>
</template>
<script>
import JoinClinic from '../../components/JoinClinic'
export default {

  name: 'DontHaveClinic',
  components: { JoinClinic },
  data () {
    return {

    }
  }
}
</script>
<style lang="css" scoped>
</style>