<template>
  <div class="container">
    <div class="py-3">
      <TariffSetting></TariffSetting>
    </div>
  </div>
</template>

<script>
import TypeBarClinicManage from '../../components/Doctor/TypeBarClinicManage.vue'
import TariffSetting from '../../components/Clinic/TariffSetting.vue'

export default {
  name: 'ClinicTariffManage',
  components: { TypeBarClinicManage, TariffSetting }
}
</script>

<style scoped>
</style>