<template>
  <div class="container bg-white p-lg">
    <div class="mx-3 d-flex justify-content-between py-3">
      <h3 class="fs-24 fw-600 txt-black ">{{ $t('workspace_setting.lbl_meeting_minute') }}</h3>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" @click="goToList"
        class="cursor-pointer">
        <path d="M6 18.4998L17.9998 6.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M17.9998 18.4998L6 6.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>
    <div class="my-8 mx-3">
      <div class="input-group radius-12 border">
        <div class="input-group-text bg-white radius-12 border-0">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.7104 20.2894L18.0004 16.6094C19.4405 14.8138 20.1379 12.5347 19.9492 10.2407C19.7605 7.94672 18.7001 5.8122 16.9859 4.27606C15.2718 2.73992 13.0342 1.91893 10.7333 1.98189C8.43243 2.04485 6.24311 2.98698 4.61553 4.61456C2.98795 6.24214 2.04582 8.43145 1.98286 10.7323C1.9199 13.0332 2.7409 15.2708 4.27704 16.9849C5.81318 18.6991 7.94769 19.7595 10.2417 19.9482C12.5357 20.1369 14.8148 19.4395 16.6104 17.9994L20.2904 21.6794C20.3834 21.7731 20.494 21.8475 20.6158 21.8983C20.7377 21.9491 20.8684 21.9752 21.0004 21.9752C21.1324 21.9752 21.2631 21.9491 21.385 21.8983C21.5068 21.8475 21.6174 21.7731 21.7104 21.6794C21.8906 21.493 21.9914 21.2438 21.9914 20.9844C21.9914 20.7251 21.8906 20.4759 21.7104 20.2894ZM11.0004 17.9994C9.61592 17.9994 8.26255 17.5889 7.1114 16.8197C5.96026 16.0505 5.06305 14.9573 4.53324 13.6782C4.00342 12.3991 3.8648 10.9917 4.1349 9.63378C4.40499 8.27592 5.07168 7.02864 6.05065 6.04967C7.02961 5.0707 8.27689 4.40402 9.63476 4.13392C10.9926 3.86382 12.4001 4.00245 13.6792 4.53226C14.9583 5.06207 16.0515 5.95928 16.8207 7.11042C17.5899 8.26157 18.0004 9.61495 18.0004 10.9994C18.0004 12.8559 17.2629 14.6364 15.9501 15.9492C14.6374 17.2619 12.8569 17.9994 11.0004 17.9994Z"
              fill="#475467" />
          </svg>
        </div>
        <input v-model="search" class="form-control bg-white radius-12"
          :placeholder="$t('circle_feature.lbl_enter_doctor_msg')" />
      </div>
    </div>
    <div class="space-y-6 mx-4">
      <div>

        <table class="table-vcenter table-mobile-md table-scroll">
          <thead>
            <tr class="border-0">
              <th class="fs-14 fw-700 txt-grey-700">{{ $t('multidisciplinary_board.lbl_document') }}</th>
              <th class="fs-14 fw-700 txt-grey-700">{{ $t('multidisciplinary_board.lbl_treatment_doctor') }}</th>
              <th class="fs-14 fw-700 txt-grey-700">{{ $t('workspace_setting.lbl_date') }}</th>
            </tr>
          </thead>
          <tbody v-for="(items, key) in list" :key="key">
            <tr v-for="c in items" :key="c" class="border-bottom my-4">
              <td><a :href="c.pdf" target="_blank" class="fs-14 fw-600 txt-grey-900">{{ c.title }}</a></td>
              <td class="fs-14 fw-400 txt-grey-900">{{ c.sign_name }}</td>
              <td class="fs-14 fw-400 txt-grey-900">{{ key }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'ConcludingList',
  data () {
    return {
      concludings: [],
      search: ''
    }
  },
  computed: {
    DoctorRequest () {
      return this.$rf.getRequest('DoctorRequest')
    },
    list () {
      return _.chain(this.concludings)
        .map(item => ({
          ...item,
          title: `Biên bản (MOM) ${item.meet_room?.name || ''} date ${moment(item.meet_time).format('DD-MM-YYYY')}`,
          dateTime: moment(item.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        }))
        .filter(item => {
          const search = _.trim(this.search)
          if (!search) {
            return true
          }

          return _.includes(_.toLower(item.sign_name), search) || _.includes(_.toLower(item.title), search)
        })
        .orderBy(['dateTime'], ['desc'])
        .groupBy('dateTime')
        .value()
    }
  },
  mounted () {
    this.getMeetRoomConcludings()
  },
  methods: {
    async getMeetRoomConcludings () {
      let params = {
        page_size: 1000,
        sort_by: 'date',
        order: 'desc'
      }
      const res = await this.DoctorRequest.getMeetRoomConcludingsV2(params)
      this.concludings = res.data.data
    },
    goToList () {
      this.$router.push({ path: '/ws-mgmt' })
    }
  }
}
</script>
<style scoped></style>
