<template>
    <div class="container">
        <div class="bound-border">
            <div class="p-10-10">
                <div v-if="type==='clinic-setting'">
                    <div v-if="isOwnerWorkspace">
                        <ClinicSetting></ClinicSetting>
                    </div>
                    <div v-if="!isOwnerWorkspace">
                        <p class="mb-0 text-center text-red robo-20-500">Bạn không có quyền xem thông tin này</p>
                    </div>
                </div>
                <div v-show="type==='clinic-accept'">
                    <div v-if="can('view-doctor-join-request')">
                        <DoctorWorkspaceClinic @showNoti="showNoti"></DoctorWorkspaceClinic>
                    </div>
                    <div v-if="!can('view-doctor-join-request')">
                        <p class="mb-0 text-center text-red robo-20-500">Bạn không có quyền xem thông tin này</p>
                    </div>
                </div>
                <div v-if="type==='clinic-banks'">
                    <div v-if="can('view-facility-info')">
                        <BankInformation></BankInformation>
                    </div>
                    <div v-if="!can('view-facility-info')">
                        <p class="mb-0 text-center text-red robo-20-500">Bạn không có quyền xem thông tin này</p>
                    </div>
                </div>
                <div v-if="type==='roles' && can('update-rule')">
                    <WorkspaceRoles></WorkspaceRoles>
                </div>
                <div v-if="type==='staffs' && (can('view-doctor-list') || can('view-staff-list'))">
                    <ListStaff></ListStaff>
                </div>
                <div v-if="type==='templates'">
                    <TemplateManagement></TemplateManagement>
                </div>
                <div v-if="type==='manage-stats'">
                    <StatManagement></StatManagement>
                </div>
                <div v-if="type==='careplan-listing'">
                    <CareplanListing></CareplanListing>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BankInformation from '../../components/BankInformation.vue'
import clinicDoctorListing from '../../components/Doctor/clinicDoctorListing.vue'
import ClinicSetting from '../../components/Clinic/ClinicSetting.vue'
import WorkspaceRoles from '../../components/Clinic/WorkspaceRoles.vue'
import ListStaff from '../../components/Clinic/ListStaff'
import TemplateManagement from '../../components/Clinic/TemplateManagement'
import StatManagement from '../../components/Clinic/StatManagement'
import DoctorWorkspaceClinic from '../../components/Clinic/_DoctorWorkspaceClinic.vue'
import appUtils from '../../utils/appUtils.js'
export default {
  name: 'ClinicOfDoctor',
  components: { BankInformation, ClinicSetting, WorkspaceRoles, DoctorWorkspaceClinic, clinicDoctorListing, ListStaff, TemplateManagement, StatManagement },
  data () {
    return {
      type: '',
      total_invites: 0
    }
  },
  mounted () {
    this.type = this.$route.query.type ? this.$route.query.type.toLowerCase() : 'clinic-setting'
    if (this.type === 'clinic-setting' && !this.isOwnerWorkspace) {
      this.type = 'staffs'
      this.changeType('staffs')
    }
  },
  computed: {
    isOwnerWorkspace () {
      let clinic = this.$globalClinic
      let user = appUtils.getLocalUser()
      if (!clinic || !clinic.id || !user || !user.doctor) return
      return clinic.doctor_id === user.doctor.id
    }
  },
  methods: {
    changeType (str_type) {
      let self = this
      self.type = str_type
      self.$router.push({ path: '/doctor/clinic', query: { type: self.type } }).catch(() => {})
    },
    checkMyClinic () {
      var clinic = localStorage.getItem('global_clinic') ? JSON.parse(localStorage.getItem('global_clinic')) : ''
      if (clinic) {
        if (clinic.pivot.doctor_id === clinic.doctor_id) {
          return true
        } else {
          return false
        }
      }
    },
    showNoti (total) {
      let self = this
      self.total_invites = total
    }
  }
}
</script>
<style scoped>
.font-size-14 {
    font-size: 14px !important;
}

.bound-border {
    background-color: #FFFFFF;
    border-radius: 5px;
}

.p-10-10 {
    padding: 10px 10px;
}

.nav-item a {
    color: #7386C7 !important;
}

.nav-item.active a {
    color: #20419B !important;
}

.navbar-expand-lg.navbar-light .nav-item.active:after {
    border-color: #20419B !important;
}
</style>