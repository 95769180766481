<template>
  <div class="container">
    <div class="pt-3">
      <StatManagement></StatManagement>
    </div>
  </div>
</template>

<script>
import TypeBarClinicManage from '../../components/Doctor/TypeBarClinicManage.vue'
import StatManagement from '../../components/Clinic/StatManagement'

export default {
  name: 'ClinicStatsManage',
  components: { TypeBarClinicManage, StatManagement }
}
</script>

<style scoped>
</style>